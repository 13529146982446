import { useState } from "react";
import type { ChangeEvent, FormEvent, InputHTMLAttributes } from "react";
import { motion } from "framer-motion";
import { IconSend } from "@tabler/icons-react";
import { cn } from "@/utils/helpers";
import { Button } from "./Button";
import { db } from "@/lib/firebase";
import { collection, addDoc } from "firebase/firestore";

interface FormData {
  name: string;
  email: string;
  subject: string;
  message: string;
}

const initialFormData: FormData = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

export function ContactSection() {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<
    "idle" | "success" | "error"
  >("idle");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus("idle");

    try {
      // Add document to Firestore
      await addDoc(collection(db, "contacts"), {
        ...formData,
        timestamp: new Date().toISOString(),
      });

      setSubmitStatus("success");
      setFormData(initialFormData);
    } catch (error) {
      console.error("Error sending message:", error);
      setSubmitStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <motion.section
      id="contact"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      className="py-16 px-4 relative"
    >
      <div className="max-w-6xl mx-auto">
        <div className="backdrop-blur-sm border border-white/10 rounded-2xl p-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h2 className="text-3xl font-light text-white mb-3">
                Get in Touch
              </h2>
              <p className="text-neutral-400 mb-6 font-light">
                Our team is ready to provide you with detailed information, or
                tailored solutions to meet your specific needs. We'd love to
                hear from you! Send us a message with your inquiries, and we'll
                get back to you as soon as possible.
              </p>
              <p className="text-neutral-400 mb-6 font-light">
                Your success is our priority, and we're committed to ensuring
                you have all the tools and knowledge to make the most of our
                innovative OCR solutions. We'd love to hear from you!
              </p>
            </div>

            <div>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <InputField
                    name="name"
                    placeholder="Name"
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <InputField
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <InputField
                  name="subject"
                  placeholder="Subject"
                  type="text"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
                <textarea
                  name="message"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={4}
                  required
                  className={cn(
                    "w-full rounded-lg bg-white/5 border border-white/10 text-white placeholder-neutral-500",
                    "focus:border-white/20 focus:ring-0 focus:outline-none",
                    "p-3 transition-colors"
                  )}
                />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="!bg-white/10 hover:!bg-white/20 backdrop-blur-sm"
                >
                  <span className="flex items-center gap-2">
                    {isSubmitting ? "Sending..." : "Send Message"}
                    <IconSend className="w-4 h-4" />
                  </span>
                </Button>

                {submitStatus === "success" && (
                  <p className="text-green-400 text-sm mt-2">
                    Message sent successfully! We'll get back to you soon.
                  </p>
                )}

                {submitStatus === "error" && (
                  <p className="text-red-400 text-sm mt-2">
                    Failed to send message. Please try again later.
                  </p>
                )}
              </form>
            </div>
            {/* Background gradient */}
            <div className="absolute bottom-0 mt-[2px] flex h-8 items-end overflow-hidden">
              <div className="flex -mb-px h-[2px] w-80 -scale-x-100">
                <div className="w-full flex-none blur-sm [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
                <div className="-ml-[100%] w-full flex-none blur-[1px] [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#0EA5E9_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
}

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type?: string;
  placeholder?: string;
  className?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

function InputField({
  type = "text",
  placeholder,
  className,
  value,
  onChange,
  required,
  name,
  ...props
}: InputFieldProps) {
  return (
    <input
      type={type}
      placeholder={placeholder}
      className={cn(
        "w-full rounded-lg bg-white/5 border border-white/10 text-white placeholder-neutral-500",
        "focus:border-white/20 focus:ring-0 focus:outline-none",
        "p-3 transition-colors",
        className
      )}
      {...props}
    />
  );
}
