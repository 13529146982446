export const ROUTES = {
  APP_LOGIN: "https://app.domain.com/login", // Replace with actual app URL
  APP_SIGNUP: "https://app.domain.com/signup", // Replace with actual app URL
  LANDING: "/",

  // Landing page sections
  HOME: "#home",
  FEATURES: "/features",
  PRICING: "/pricing",
  CONTACT: "#contact",
  BLOG: "/blog",

  // External routes
  PRO: "/pro",
} as const;
